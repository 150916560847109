import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Credentials,
  List,
  ListItem,
  SidebarBottom,
  SidebarCenter,
  SidebarContainer,
  SidebarTop,
} from "./style";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import BallotIcon from "@mui/icons-material/Ballot";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import EventIcon from '@mui/icons-material/Event';
import PinDropIcon from '@mui/icons-material/PinDrop';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = async () => {
    if (dispatch) dispatch({ type: "LOGOUT", payload: null });
    navigate("/login");;
  }
  return (
    <SidebarContainer>
      <SidebarTop>
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="logo">{"TORCH"}</div>
        </Link>
      </SidebarTop>
      <SidebarCenter>
        <List>
          <h2 className="itemGroupTitle">MAIN</h2>
          <Link to="/" style={{ textDecoration: "none" }}>
            <ListItem>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </ListItem>
          </Link>
          <h2 className="itemGroupTitle">FLOORPLANS</h2>
          <Link to="/events" style={{ textDecoration: "none" }}>
            <ListItem>
              <EventIcon className="icon" />
              <span>Events</span>
            </ListItem>
          </Link>
          <Link to="/maps" style={{ textDecoration: "none" }}>
            <ListItem>
              <PinDropIcon className="icon" />
              <span>Maps</span>
            </ListItem>
          </Link>
          <Link to="/companys" style={{ textDecoration: "none" }}>
            <ListItem>
              <BusinessIcon className="icon" />
              <span>Companies</span>
            </ListItem>
          </Link>
          <Link to="/applicants" style={{ textDecoration: "none" }}>
            <ListItem>
              <AssignmentIcon className="icon" />
              <span>Exhibitors</span>
            </ListItem>
          </Link>
          <h2 className="itemGroupTitle">Exhibitor Services</h2>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <ListItem>
              <BallotIcon className="icon" />
              <span>Products</span>
            </ListItem>
          </Link>
          <Link to="/orders" style={{ textDecoration: "none" }}>
            <ListItem>
              <BallotIcon className="icon" />
              <span>Orders</span>
            </ListItem>
          </Link>
          {/* <h2 className="itemGroupTitle">LISTS</h2>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <ListItem>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </ListItem>
          </Link>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <ListItem>
              <CategoryIcon className="icon" />
              <span>Products</span>
            </ListItem>
          </Link>
          <Link to="/orders" style={{ textDecoration: "none" }}>
            <ListItem>
              <BallotIcon className="icon" />
              <span>Orders</span>
            </ListItem>
          </Link>
          <ListItem>
            <DeliveryDiningIcon className="icon" />
            <span>Delivery</span>
          </ListItem> */}

          {/* <h2 className="itemGroupTitle">USEFUL</h2>
          <ListItem>
            <QueryStatsIcon className="icon" />
            <span>Stats</span>
          </ListItem>
          <ListItem>
            <CircleNotificationsIcon className="icon" />
            <span>Notifications</span>
          </ListItem> */}


          <h2 className="itemGroupTitle">USER</h2>
          <Link to="/users/profile" style={{ textDecoration: "none" }}>
            <ListItem>
              <AccountBoxIcon className="icon" />
              <span>Profile</span>
            </ListItem>
          </Link>
          <Link to="/colleagues" style={{ textDecoration: "none" }}>
            <ListItem>
              <GroupWorkIcon className="icon" />
              <span>Colleagues</span>
            </ListItem>
          </Link>
          <Link to="/permissions" style={{ textDecoration: "none" }}>
            <ListItem>
              <VerifiedUserIcon className="icon" />
              <span>Permissions</span>
            </ListItem>
          </Link>

          <h2 className="itemGroupTitle">MAINTENANCE</h2>
          <ListItem>
            <HealthAndSafetyIcon className="icon" />
            <span>System</span>
          </ListItem>
          <ListItem>
            <SettingsSuggestIcon className="icon" />
            <span>Settings</span>
          </ListItem>
          <ListItem>
            <TextSnippetIcon className="icon" />
            <span>Logs</span>
          </ListItem>

          <ListItem></ListItem>

          <ListItem onClick={logout}>
            <LogoutIcon className="icon" />
            <span>Log Out</span>
          </ListItem>
        </List>
      </SidebarCenter>
      <SidebarBottom>
        {/* <h2 className="itemGroupTitle">Toggle Theme</h2>
        <div className="toggleGroup">
          <div
            className="box lightTheme"
            onClick={() => {
              if (dispatch) dispatch({ type: "LIGHT" });
            }}
          ></div>
          <div
            onClick={() => {
              if (dispatch) dispatch({ type: "DARK" });
            }}
            className="box darkTheme"
          ></div>
        </div> */}
        <Credentials>
          <p>@TORCH TEAM</p>
          <p>2023</p>
        </Credentials>
      </SidebarBottom>
    </SidebarContainer>
  );
};

export default Sidebar;
