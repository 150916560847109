import { useState, useEffect } from "react";
import Layout from "../../../components/layout/Layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  ActionContainer,
  AddUser,
  DataGridContainer,
  DataGridWrapper,
} from "./style";
import { Link, useLocation } from "react-router-dom";
import { productsColumns } from "./productsForm";
import { getEventProducts } from "../../../api/eventsService";
import { useNavigate, useParams } from "react-router-dom";

type ListType = "User" | "Product" | "Order";

const Products = () => {
  const location = useLocation();
  const type: ListType = "Product"
  const { id }: any = useParams();

  // const data = useData(type);
  const [data, setData] = useState<any[]>([]);

  const handleDelete = async (id: string) => {
    try {

    } catch (err) {
      console.log(err);
    }
  };
  const actions: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Link
              to={`/events/${id}/products/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">View</button>
            </Link>
            <button
              onClick={() => handleDelete(params.row.id)}
              className="btn deleteBtn"
            >
              Delete
            </button>
          </ActionContainer>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const res = await getEventProducts(id);
      if (res) {
        setData(res);
      }
    }
    fetchData();
  }, [])
  return (
    <Layout>
      <AddUser>
        <h1 className="title">Event Products</h1>
        <Link
          to={`/events/${id}/products/new`}
          state={{ type }}
          style={{ textDecoration: "none" }}
        >
          <button className="add">Add {type}</button>
        </Link>
      </AddUser>
      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "white" }}
            rows={data}
            columns={
              productsColumns.concat(actions)
            }
            pageSize={10}
            rowsPerPageOptions={[10]}
          // checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Layout>
  );
};

export default Products;
