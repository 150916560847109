import { Container, HeaderTitle, SubmitButton, InputForm, Left, Right } from "./style";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { FormDataSource } from "../../util/formDataSource";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

import { useAppLocation } from "../../hooks/useAppLocation";
import { addProduct, getProduct, updateProduct } from '../../api/productsService';
import { getCategorys } from "../../api/categorysService";
import { formProductInput } from "./productsForm";
import { toast } from 'react-toastify';

interface Props {
  Inputs?: FormDataSource[];
  actionMode?: string;
}
export type IUploadStatus = "NO_IMAGE" | "UPLOADING" | "UPLOADED";

const NewProduct: React.FC<Props> = ({ Inputs = formProductInput, actionMode = 'new' }) => {
  const { id }: any = useParams();

  // const [events, setEvents]: any = useState([]);
  const [options, setOptions]: any = useState({
    category: []
  });

  // states
  const [file, setFile] = useState<File | null>(null);
  // upload status for disabling submit button
  const [data, setData]: any = useState({
    timestamp: new Date(),
    logo: `uploads/images/noimage.png`,
  });
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>("NO_IMAGE");

  const navigate = useNavigate();

  useEffect(() => {

    getCategorys()
      .then((categorys: any) => {
        setOptions({ ...options, category_id_options: categorys });
        setData({
          ...data,
          category_id: categorys.length > 0 ? categorys[0].id : ''
        });
      })
      .catch((err: any) => console.log(err.message));
  }, [])
  useEffect(() => {
    if (actionMode == 'edit') {
      getProduct(id).then((item) => {
        setData({
          ...item,
          category_id: item.category_id
        });
      }).catch((err) => {
        console.log('error', err.message);
      })
    }
  }, [actionMode]);

  useEffect(() => {
    if (file) {
      setData({
        ...data,
        image: file.name,
        file: file
      });
      setUploadStatus("UPLOADED");
    }
  }, [file]);

  // handle form submiting
  const handleSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    // Destructure required fields from the data object
    const isFormValid = Inputs.every((input) => {
      if(input.type == 'blank') { return true }
      const value = data[input.id];
      return value !== undefined && value !== ""; // Ensure value is not empty or undefined
    });

    // Validate required fields
    if (
      !isFormValid
    ) {
      console.log('All fields are required.');
      toast.error('All fields are required.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    let result = null;
    try {
      if (actionMode === 'edit') {
        result = await updateProduct(data);
      } else {
        result = await addProduct(data);
      }
      navigate(-1);//'/Products'

    } catch (error: any) {
      console.log(error.message);
    }

  };

  //handle image upload
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setUploadStatus("UPLOADING");
    } else {
      setUploadStatus("NO_IMAGE");
    }
  };

  const handleInput = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  return (
    <Layout>
      <HeaderTitle>{actionMode === 'edit' ? 'Edit Product' : 'Add New Product'}</HeaderTitle>
      <Container>
        {/* Image upload */}
        <Left>
          <img
            src={
              file
                ? URL.createObjectURL(file)
                : `${process.env.REACT_APP_SERVER_URL}/${data.image_url}`
            }
            alt="img"
            className="img"
          />
        </Left>
        <Right>
          {/* Input form */}
          <InputForm
            onSubmit={(e: any) => handleSubmit(e)}
          >
            <div className="file">
              <p className="inputTitle">Upload Logo:</p>
              <label htmlFor="file">
                <DriveFolderUploadIcon className="icon" />
              </label>
            </div>
            <input
              onChange={handleFile}
              name="img"
              style={{ display: "none" }}
              type="file"
              id="file"
            />
            {/* Generating inputs from JSON */}
            {Inputs.map((input) => (
              <div key={input.id} className="input">
                <label className="inputTitle">{input.label}</label>
                <br />
                {input.type == 'select' && (<select
                  onChange={handleInput}
                  id={input.id}
                  className="inputField"
                  value={data[input.id]}
                >
                  {options[input.id + '_options']?.map((option: any) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>)
                }
                {input.type == 'text' && (<input
                  onChange={handleInput}
                  id={input.id}
                  className="inputField"
                  type={input.type}
                  placeholder={input.placeholder}
                  value={data[input.id]}
                />)}

              </div>
            ))}
            {/* <div className="full-width">
              <label className="inputTitle">{"Description"}</label>
              <br />
              <textarea
                onChange={handleInput}
                id={'description'}
                className="descriptionField"
                placeholder={"Product Description (max 50 words)"}
                value={data['description']}
                rows={5}
              />
            </div> */}
            {uploadStatus === "UPLOADING" ? (
              <SubmitButton disabled type="submit">
                Uploading...
              </SubmitButton>
            ) : (
              <SubmitButton type="submit">SUBMIT</SubmitButton>
            )}
          </InputForm>
        </Right>
      </Container>
    </Layout>
  );
};

export default NewProduct;
