import { Container, HeaderTitle, SubmitButton, InputForm, Left, Right } from "./style";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { FormDataSource } from "../../util/formDataSource";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Link, useLocation } from "react-router-dom";

import { useAppLocation } from "../../hooks/useAppLocation";
import { addProduct, getProduct, updateProduct } from '../../api/productsService';
import { getCategorys } from "../../api/categorysService";
import { getOrder } from "../../api/ordersService";
import { formProductInput } from "./ordersForm";
import { toast } from 'react-toastify';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { productsColumns } from "./ordersForm";

import {
  ActionContainer,
  DataGridContainer,
  DataGridWrapper,
} from "./style";

interface Props {
  Inputs?: FormDataSource[];
  actionMode?: string;
}
export type IUploadStatus = "NO_IMAGE" | "UPLOADING" | "UPLOADED";

const NewProduct: React.FC<Props> = ({ Inputs = formProductInput, actionMode = 'view' }) => {
  const { id }: any = useParams();

  // const [events, setEvents]: any = useState([]);
  const [options, setOptions]: any = useState({
    category: []
  });

  // states
  const [file, setFile] = useState<File | null>(null);
  // upload status for disabling submit button
  const [data, setData]: any = useState({
    orderItems: [],
    timestamp: new Date(),
    logo: `uploads/images/noimage.png`,
  });
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>("NO_IMAGE");

  const actions: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Link
              to={`/products/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">View</button>
            </Link>
            {/* <button
                onClick={() => handleDelete(params.row.id)}
                className="btn deleteBtn"
              >
                Delete
              </button> */}
          </ActionContainer>
        );
      },
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {

    getCategorys()
      .then((categorys: any) => {
        setOptions({ ...options, category_id_options: categorys });
        setData({
          ...data,
          category_id: categorys.length > 0 ? categorys[0].id : ''
        });
      })
      .catch((err: any) => console.log(err.message));
  }, []);

  useEffect(() => {
    if (actionMode == 'view') {
      getOrder(id).then((order) => {
        setData({
          ...order,
          orderItems: order?.order_items.map((item: any) => ({
            ...item.product,
            price: item.price,
            quantity: item.quantity,
            updated_at: item.updated_at
          }))
        });
      }).catch((err) => {
        console.log('error', err.message);
      })
    }
  }, [id, actionMode]);

  useEffect(() => {
    if (file) {
      setData({
        ...data,
        image: file.name,
        file: file
      });
      setUploadStatus("UPLOADED");
    }
  }, [file]);

  // handle form submiting
  const handleSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    // Destructure required fields from the data object
    const isFormValid = Inputs.every((input) => {
      const value = data[input.id];
      return value !== undefined && value !== ""; // Ensure value is not empty or undefined
    });

    // Validate required fields
    if (
      !isFormValid
    ) {
      console.log('All fields are required.');
      toast.error('All fields are required.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    let result = null;
    try {
      if (actionMode === 'edit') {
        result = await updateProduct(data);
      } else {
        result = await addProduct(data);
      }
      navigate(-1);//'/Products'

    } catch (error: any) {
      console.log(error.message);
    }

  };

  //handle image upload
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setUploadStatus("UPLOADING");
    } else {
      setUploadStatus("NO_IMAGE");
    }
  };

  const handleInput = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  return (
    <Layout>
      <HeaderTitle>
        <div>
          {`${actionMode?.toLocaleUpperCase()} ORDER`}
        </div>
        <p>
          {`${data?.event?.full_name || ''}`}
        </p>
      </HeaderTitle>
      <Container>
        <DataGridWrapper>
          <DataGridContainer>
            <DataGrid
              style={{ backgroundColor: "white" }}
              rows={data?.orderItems}
              columns={
                // productsColumns.concat(actions)
                productsColumns
              }
              pageSize={10}
              rowsPerPageOptions={[10]}
            // checkboxSelection
            />
          </DataGridContainer>
        </DataGridWrapper>
      </Container>
    </Layout>
  );
};

export default NewProduct;
