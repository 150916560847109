import { HTMLInputTypeAttribute } from "react";
import { GridColDef } from "@mui/x-data-grid";

export interface FormDataSource {
  id: string;
  label: string;
  type: HTMLInputTypeAttribute;
  placeholder: string;
  readOnly?: boolean;
}
export const ordersColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "user",
    headerName: "Customer",
    width: 250,
    renderCell: (params) => {
      return (
        <div>
          <p>{params.row.user?.username}</p>
        </div>
      );
    },
  },

  {
    field: "event",
    headerName: "Event",
    width: 350,
    renderCell: (params) => {
      return <span>{`${params.row.event?.full_name}`}</span>;
    },
  },
  {
    field: "total_price",
    headerName: "Total Price",
    width: 150,
    renderCell: (params) => {
      return <span>{`${params.row.total_price} ${params.row.event?.currency?.toUpperCase()}`}</span>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    // renderCell: (params: GridValueGetterParams) => {
    //   return <span>{`${params.row.stock} pieces`}</span>;
    // },
  },
  {
    field: "updated_at",
    headerName: "Date",
    width: 150,
    renderCell: (params) => {
      return <span>{`${params.row.updated_at}`}</span>;
    },
  },
];

export const productsColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "name",
    headerName: "Title",
    width: 250,
    // renderCell: (params: GridValueGetterParams) => {
    //   return (
    //     <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //       <img
    //         style={{
    //           width: "40px",
    //           height: "40px",
    //           borderRadius: "50%",
    //           objectFit: "cover",
    //         }}
    //         src={params.row.img}
    //         alt={params.row.title}
    //       />
    //       <p>{params.row.title}</p>
    //     </div>
    //   );
    // },
  },

  {
    field: "description",
    headerName: "Description",
    width: 350,
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    renderCell: (params) => {
      return <span>{`${params.row.category.name}`}</span>;
    },
  },
  {
    field: "price",
    headerName: "Price",
    width: 150,
    renderCell: (params) => {
      return <span>{`£ ${params.row.price}`}</span>;
    },
  },
  {
    field: "quantity",
    headerName: "Quantity",
    width: 150,
    // renderCell: (params: GridValueGetterParams) => {
    //   return <span>{`${params.row.stock} pieces`}</span>;
    // },
  },
  // {
  //   field: "updated_at",
  //   headerName: "Date",
  //   width: 150,
  //   // renderCell: (params: GridValueGetterParams) => {
  //   //   return <span>{`${params.row.stock} pieces`}</span>;
  //   // },
  // },
  
];

export const formProductInput: FormDataSource[] = [
  {
    id: "name",
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: "category_id",
    label: "Category",
    type: "select",
    placeholder: "Category",
  },
  {
    id: "price",
    label: "Price(£)",
    type: "text",
    placeholder: "100",
  },
  {
    id: "stock",
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];

