import { useEffect, useState } from 'react';
import Layout from "../../components/layout/Layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { eventsColumns } from "./eventsTableData";
import { getEvents, deleteEvent } from "../../api/eventsService";

import {
  ActionContainer,
  AddEvent,
  DataGridContainer,
  DataGridWrapper,
} from "./style";
import { Link, useLocation } from "react-router-dom";
import { useData } from "../../hooks/useData";

type ListType = "User" | "Product" | "Order" | "Events";

const Events = () => {
  const location = useLocation();
  // change content on different path
  const type: ListType = "Events";

  const [events, setEvents]: any = useState([]);

  // Custom Hook for fathing data
  const data = useData(type);

  useEffect(() => {
    getEvents().then((data) => {
      const map_data: any = [];
      data.map((item: any) => {
        map_data.push({
          ...item,
          areas: `${item.width | 0} * ${item.height | 0}`,
        })
        item.width = item.width
      })
      setEvents(map_data);
    });
    return () => {

    }
  }, []);


  // Handle deleting user from db
  const handleDelete = async (id: string) => {
    try {
      await deleteEvent(id);
      const filtered_data: any = events.filter((item: any) => item.id !== id);
      setEvents(filtered_data);
    } catch (err) {
      console.log(err);
    }
  };
  // Generic table fields
  const actions: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Link
              to={`${location.pathname}/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">Edit</button>
            </Link>
            {/* <Link
              to={`/exhibitors/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">View</button>
            </Link> */}
            <Link
              to={`${location.pathname}/${params.row.id}/products`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">Products</button>
            </Link>
            <button
              onClick={() => handleDelete(params.row.id)}
              className="btn deleteBtn"
            >
              Delete
            </button>
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <Layout>
      {!(location.pathname === "/orders") && (
        <AddEvent>
          <h1 className="title">Events</h1>
          <Link
            to={`${location.pathname}/new`}
            state={{ type }}
            style={{ textDecoration: "none" }}
          >
            <button className="add">Add Event</button>
          </Link>
        </AddEvent>
      )}

      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "#fff", }}
            rows={events}
            columns={eventsColumns.concat(actions)}
            pageSize={10}
            rowsPerPageOptions={[10]}
          // checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Layout>
  );
};

export default Events;
