import { Container, HeaderTitle, SubmitButton, InputForm } from "./style";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { FormDataSource } from "../../util/formDataSource";
// import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { useAppLocation } from "../../hooks/useAppLocation";
import { addApplicant, getApplicant, updateApplicant } from '../../api/applicantsService';
import { getEvents } from "../../api/eventsService";
import { getCompanys } from "../../api/companysService";

interface Props {
  Inputs: FormDataSource[];
  actionMode: string;
}
export type IUploadStatus = "NO_IMAGE" | "UPLOADING" | "UPLOADED";

const NewEvent: React.FC<Props> = ({ Inputs, actionMode }) => {
  const { id }: any = useParams();

  // states
  const [file, setFile] = useState<File | null>(null);
  // upload status for disabling submit button
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>("NO_IMAGE");
  const [events, setEvents]: any = useState([]);
  const [companys, setCompanys]: any = useState([]);
  const [status, setstatus]: any = useState([
    { id: 'pending', name: 'Pending' },
    { id: 'approved', name: 'Approved' },
  ]);

  const [payments, setPayments]: any = useState([
    { id: 'none', name: 'None' },
    { id: 'offline', name: 'Offline' },
    { id: 'stripe', name: 'Stripe' },
  ]);

  const [data, setData]: any = useState({
    status: "pending",
    timeStamp: new Date(),
  });
  const navigate = useNavigate();
  const location = useAppLocation();

  useEffect(() => {
    getEvents()
      .then((events: any) => setEvents(events))
      .catch((err: any) => console.log(err.message));

    getCompanys('')
      .then((companys: any) => {
        // const available_companys = companys.filter( (company: any) => company.event.deleted == "no");
        // setCompanys(available_companys);
        const filteredData = [];
        const emailSet = new Set();

        for (const item of companys) {
          if (!emailSet.has(item.primary_email)) {
            emailSet.add(item.primary_email);
            filteredData.push(item);
          }
        }
        setCompanys(filteredData);
      })
      .catch((err: any) => console.log(err.message));

  }, []);

  useEffect(() => {
    console.log(actionMode);
    if (actionMode == 'edit') {
      getApplicant(id).then((item) => {
        // Ensure the promocode field is correctly structured in state
        setData({
          ...item,
          promocode_staff: item.promocode?.staff || '',
          promocode_delegate: item.promocode?.delegate || '',
          promocode_staff_quantity: item.promocode?.staff_quantity || '',
          promocode_delegate_quantity: item.promocode?.delegate_quantity || '',
        });
      }).catch((err) => {
        console.log('error', err.message);
      })
    }
  }, [actionMode]);

  useEffect(() => {
    if (file) {
      // setData({
      //   ...data,
      //   image: file.name,
      //   file: file
      // });
      // setUploadStatus("UPLOADED");
    }
  }, [file]);

  // handle form submiting
  const handletSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    let result = null;
    try {
      if (actionMode === 'edit') {
        result = await updateApplicant({
          ...data,
          promocode: {
            staff: data.promocode_staff,
            delegate: data.promocode_delegate,
            staff_quantity: data.promocode_staff_quantity,
            delegate_quantity: data.promocode_delegate_quantity,
          }
        });
      } else {
        result = await addApplicant({
          ...data,
          promocode: {
            staff: data.promocode_staff,
            delegate: data.promocode_delegate,
            staff_quantity: data.promocode_staff_quantity,
            delegate_quantity: data.promocode_delegate_quantity,
          }
        });
      }
      navigate('/applicants');
    } catch (error: any) {
      console.log(error.message);
    }

  };

  //handle image upload
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setUploadStatus("UPLOADING");
    } else {
      setUploadStatus("NO_IMAGE");
    }
  };

  // handle form inputs
  const handleInput = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };
  const handleCompanyInput = (e: any) => {
    // handleInput(e);
    const id = e.target.id;
    const value = e.target.value;
    const selectedCompany = companys.find((item: any) => item.id == value);
    setData({
      ...data,
      company_id: value,
      name: selectedCompany?.name
    });

  };
  // JSX Return
  return (
    <Layout>
      <HeaderTitle>{actionMode == 'edit' ? `Edit Applicant` : `New Applicant`}</HeaderTitle>
      <Container>
        {/* Input form */}
        <InputForm
          onSubmit={(e: any) => handletSubmit(e)}
        >
          <div className="input">
            <label htmlFor="company_id" className="inputTitle">Company</label>
            <br />
            <select id="company_id" className="inputField" value={data.company_id} onChange={handleCompanyInput}>
              <option value="-1">Select Company</option>
              {
                companys.map((item: any) => {
                  return (<option key={item.id} value={item.id}>{`${item.name} - ${item.event?.title || ''}`}</option>)
                })
              }
            </select>
          </div>
          <div className="input">
            <label className="inputTitle">Name</label>
            <br />
            <input
              onChange={handleInput}
              id="name"
              className="inputField"
              type="text"
              placeholder="Please input Name"
              value={data.name}
              readOnly
            />
          </div>

          <div className="input">
            <label htmlFor="event_id" className="inputTitle">Event</label>
            <br />
            <select id="event_id" className="inputField" value={data.event_id} onChange={handleInput}>
              <option value="-1">Select Event</option>
              {
                events.map((item: any) => {
                  return (<option key={item.id} value={item.id}>{item.title}</option>)
                })
              }
            </select>
          </div>


          <div className="input">
            <label className="inputTitle">Booth No</label>
            <br />
            <input
              onChange={handleInput}
              id="booth"
              className="inputField"
              type="number"
              placeholder="Please input Booth"
              value={data.booth}
            />
          </div>

          <div className="input">
            <label htmlFor="payment" className="inputTitle">Payment Type</label>
            <br />
            <select id="payment" className="inputField" value={data.payment} onChange={handleInput}>
              <option value="-1">Select Payment</option>
              {
                payments.map((item: any) => {
                  return (<option key={item.id} value={item.id}>{item.name}</option>)
                })
              }
            </select>
          </div>
          <div className="input">
            <label htmlFor="status" className="inputTitle">Status</label>
            <br />
            <select id="status" className="inputField" value={data.status} onChange={handleInput}>
              <option value="-1">Select Status</option>
              {
                status.map((item: any) => {
                  return (<option key={item.id} value={item.id}>{item.name}</option>)
                })
              }
            </select>
          </div>

          <div className="input">
            <label className="inputTitle">Exhibitor Staff Registration Promo Code</label>
            <br />
            <input
              onChange={handleInput}
              id="promocode_staff"
              className="inputField"
              type="text"
              placeholder="Please input promo code"
              value={data.promocode_staff}
            />
          </div>
          <div className="input">
            <label className="inputTitle">Exhibitor Delegate Registration Promo Code</label>
            <br />
            <input
              onChange={handleInput}
              id="promocode_delegate"
              className="inputField"
              type="text"
              placeholder="Please input promo code"
              value={data.promocode_delegate}
            />
          </div>
          <div className="input">
            <label className="inputTitle">Exhibitor Staff Quantity</label>
            <br />
            <input
              onChange={handleInput}
              id="promocode_staff_quantity"
              className="inputField"
              type="number"
              placeholder="Please input promo code"
              value={data.promocode_staff_quantity}
            />
          </div>
          <div className="input">
            <label className="inputTitle">Exhibitor Delegate Quantity</label>
            <br />
            <input
              onChange={handleInput}
              id="promocode_delegate_quantity"
              className="inputField"
              type="number"
              placeholder="Please input promo code"
              value={data.promocode_delegate_quantity}
            />
          </div>

          {uploadStatus === "UPLOADING" ? (
            <SubmitButton disabled type="submit">
              Uploading...
            </SubmitButton>
          ) : (
            <SubmitButton type="submit">SUBMIT</SubmitButton>
          )}
        </InputForm>

      </Container>
    </Layout>
  );
};

export default NewEvent;
